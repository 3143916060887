.maindiv {
    padding-left:2%;
}

.topdiv {
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 5%;
    padding-bottom:4%;
    letter-spacing: 1.5px;
    border-bottom:1.5px solid #51576d;
    font-weight: 600;
    color: #ca9ee6;
}

.subdiv {
    color: #babbf1;
    font-weight: 400;
    font-size: 10pt;
}

.middiv {
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 5%;
    padding-bottom:4%;
}

p {
    color:#c6d0f5;
}

a {
    color:#babbf1;
}

.contacticonbox {
    display:flex;
    padding-top:1%;
    padding-bottom:1%;
}

.contacticon {
    margin-right: 0.8%;
}