.flex-container {
    display:flex;
    flex-direction: row;
    background-color: #232634;
    width:100%;
    border-bottom: 1.5px solid #51576d;
    letter-spacing: 1.5px;
}

.flex-item {
    padding-left: 2.5%;
    padding-right: 2.5%;
}

.flex-item-rest {
    margin-bottom: 1.5%;
    margin-top: 1.5%;
}

.non-second {
    border-left: 1.5px solid #51576d;
}

.flex-item-main {
    padding-bottom: 1.5%;
    padding-top: 1.5%;
    border-right: 1.5px solid #51576d;
}

.flex-item a {
    color:#babbf1;
    text-decoration: none;
}

.flex-item-main a {
    color: #ca9ee6;
    font-weight: 600;
}