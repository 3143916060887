.sectiondiv {
    letter-spacing: 1.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.collegetitle {
    letter-spacing: 1.5px;
    margin-bottom:0.75%;
    font-size:16px;
    color: #babbf1;
}

.skillsline {
    font: size 14px;
    margin-bottom:0.50%;
    letter-spacing: 1.25px;
}

.exptitle {
    margin-bottom:0.5%;
    font-weight:500;
    color: #babbf1;
}

.collegedesc {
    margin-top:0;
    padding-top:0;
    font-size: 14px;
    letter-spacing: 1.5px;
    color:#babbf1;
}

h3 {
    font-weight:600;
    color: #ca9ee6;
}

h4 {
    letter-spacing: 1.5px;
    margin-bottom:1px;
    font-weight:600;
}

ul {
    list-style-position: inside;
    margin-left: 0; 
    padding-left: 0;
}

li {
    font-size:14px;
    margin-left:0;
    color:#c6d0f5;
    margin-bottom:0.75%;
}

b {
    color: #babbf1
}
